.wallet {
	background-color: rgba(255,255,255,0.7) !important;
	width: 300px
}
.img-heart-wallet {
	
}

.img-coin-wallet {
	
}
.wallet:hover .img-coin-wallet {
	transform: rotateY(45deg);
	animation: rotateAnimation 1s linear infinite;
}

.wallet:hover .img-heart-wallet {
	animation: beat .25s infinite alternate;
	transform-origin: center;
}

.dashboard-header {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 120px 0; 
	background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
	background-size: 400% 400%;
	animation: change 10s ease-in-out infinite;
}
.dashboard-header-text {
	text-align: center;
	color: white;
	margin-bottom: 20px !important;
}

#demo-simple-select-outlined {
	display: flex;
	align-items: center;
	padding: 14px;
}


@keyframes rotateAnimation {
	from {transform: rotateY(45deg);}
	to {transform: rotateY(225deg);}
}

@keyframes beat{
	to { transform: scale(1.4); }
}

@keyframes change {
	0% {
		background-position: 0 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0 50%;
	}
}