iframe {
  border: 4px solid #000000;
  border-radius: 4px;
  min-width: 200px;
  min-height: 200px;
  max-width: 480px;
  max-height: 320px;
  display: block;
  outline: 0px solid transparent;
}

div[data-youtube-video] > iframe {
  cursor: move;
  aspect-ratio: 16 / 9;
  width: 100%;
}

.ProseMirror-selectednode iframe {
  transition: outline 0.15s;
  /* outline: 6px solid #fbbf24; */
  outline: none;
}

@media only screen and (max-width: 480px) {
  div[data-youtube-video] > iframe {
    max-height: 50px;
  }
}

@media only screen and (max-width: 720px) {
  div[data-youtube-video] > iframe {
    max-height: 100px;
  }
}
