.avatarSecond:hover .circle-1,
.avatarFirst:hover .circle-1,
.avatarThird:hover .circle-1 {
    transform: rotate(360deg);
}

.avatarSecond:hover .circle-2,
.avatarFirst:hover .circle-2,
.avatarThird:hover .circle-2 {
    transform: rotate(-360deg);
}