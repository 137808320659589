._1Lxpd {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
    padding: 0;
  }
  
  ._1Lxpd span {
    z-index: 1000;
    cursor: pointer;
  }
  
  ._3tZKZ {
    list-style: none;
    padding: 0;
    position: relative;
  }
  
  ._215Bn {
    width: 260px;
    height: 260px;
    margin: 0px 140px;
    
  }
  
  ._2ocTZ {
    color: cornflowerblue;
    font-size: 2em;
    padding: 5px;
  }
  
  ._2YVEG {
    color: cornflowerblue;
    font-size: 2em;
    padding: 5px;
  }
  
  ._xsi02 {
    width: 100%;
    height: 100%;
    /* box-shadow: 0 10px 5px rgba(0, 0, 0, 0.1); */
    transition: all 0.5s ease-in-out;
    opacity: 0; 
    position: absolute;
    transform: scale(1) translateX(40px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  ._xsi02 img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    overflow: hidden;
  }
  
  ._xsi02._3sodH {
    opacity: 1;
    transform: scale(1) translateY(0);
    z-index: 3;
  }
  
  ._xsi02._1uQwZ {
    opacity: 0.2;
    z-index: 2;
    transform: scale(0.9) translateX(140px);
  }
  
  ._xsi02._KqFRp {
    transform: scale(0.9) translateX(-140px);
    z-index: 1;
    opacity: 0.2;
  }
  
  ._xsi02._3hvs3 {
    transform: scale(1) translateX(0px);
    z-index: 1;
    opacity: 0;
  }
  